import { render, staticRenderFns } from "./chatHeader.vue?vue&type=template&id=667647dc&scoped=true"
import script from "./chatHeader.vue?vue&type=script&lang=js"
export * from "./chatHeader.vue?vue&type=script&lang=js"
import style0 from "./chatHeader.vue?vue&type=style&index=0&id=667647dc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667647dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_en-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('667647dc')) {
      api.createRecord('667647dc', component.options)
    } else {
      api.reload('667647dc', component.options)
    }
    module.hot.accept("./chatHeader.vue?vue&type=template&id=667647dc&scoped=true", function () {
      api.rerender('667647dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/chat/view/chatHeader.vue"
export default component.exports