var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-container",
      class: { "file-message-wrap": _vm.isFile },
    },
    [
      _vm.isText
        ? _c("div", {
            staticClass: "is-text",
            domProps: {
              innerHTML: _vm._s(_vm.highLight(_vm.showHtmlText, _vm.keywords)),
            },
          })
        : _vm.isImage
        ? _c("div", { staticClass: "is-image" }, [
            _c("img", {
              attrs: { src: _vm.msg.file.url },
              on: {
                click: function ($event) {
                  return _vm.downFile(_vm.msg.file)
                },
              },
            }),
          ])
        : _vm.isMap
        ? _c("div", { staticClass: "is-map" }, [
            _c("img", {
              staticClass: "map",
              attrs: { src: require("../../../assets/chat/map.png"), alt: "" },
            }),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.msg.geo.title)),
            ]),
          ])
        : _vm.isChartlet
        ? _c("div", { staticClass: "is-chartlet no-boder" }, [
            _c("img", { attrs: { src: _vm.chartletData.img, alt: "" } }),
          ])
        : _vm.isShare
        ? _c("div", { staticClass: "is-share" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("div", [_vm._v(_vm._s(_vm.shareData.title))]),
              _c("div", [_vm._v(_vm._s(_vm.shareData.data.statusName || ""))]),
            ]),
            _c("div", { staticClass: "line" }),
            _c(
              "div",
              { staticClass: "detail-box" },
              _vm._l(_vm.shareData.leftList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  _c("div", { staticClass: "left" }, [_vm._v(_vm._s(item))]),
                  _c("div", { staticClass: "right" }, [
                    _vm._v(_vm._s(_vm.shareData.rightList[index])),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm.isFile
        ? _c(
            "div",
            { staticClass: "is-file" },
            [
              _c("en-icon", {
                staticClass: "file-icon",
                attrs: {
                  name: _vm.suffix(_vm.msg.file.name).iconName,
                  color: _vm.suffix(_vm.msg.file.name).iconColor,
                },
              }),
              _c("div", { staticClass: "file-info" }, [
                _c("div", { staticClass: "file-name" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.msg.file.name +
                          "(" +
                          _vm.fileSizeTrans(_vm.msg.file.size) +
                          ")"
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "file-status" }, [
                  _vm._v(" " + _vm._s("发送成功") + " "),
                  _c(
                    "span",
                    {
                      staticClass: "down-btn",
                      on: {
                        click: function ($event) {
                          return _vm.downFile(_vm.msg.file)
                        },
                      },
                    },
                    [_vm._v("下载")]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _c("div", [_vm._v(" " + _vm._s(_vm.msg.text) + " ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }