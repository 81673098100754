var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeDialog,
              expression: "closeDialog",
            },
          ],
          staticClass: "app-list",
        },
        [
          _c("en-title-card", { attrs: { name: "发起应用" } }, [
            _c(
              "div",
              { attrs: { name: "right" } },
              [_c("en-icon", { attrs: { name: "guanbi" } })],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "app-list-main" },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                { key: item.name, staticClass: "app-list-item" },
                [
                  _c("div", { staticClass: "app-list-title" }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "app-list-container" },
                    _vm._l(item.list, function (el) {
                      return _c(
                        "div",
                        {
                          key: el.name,
                          staticClass: "app-item",
                          on: { click: _vm.closeDialog },
                        },
                        [
                          _c("div", { staticClass: "app-item-container" }, [
                            _c(
                              "div",
                              { staticClass: "app-item-icon" },
                              [
                                _c("en-icon", {
                                  attrs: { name: el.icon, size: "20px" },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "app-item-name" }, [
                              _vm._v(" " + _vm._s(el.name) + " "),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }