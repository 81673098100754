<template>
  <div
    v-if="value"
    v-click-outside="closeEmojiPicker"
    class="emoji-picker"
  >
    <div class="emoji-container">
      <div v-if="currentTabData && currentTabData.list && currentTabData.list.length" ref="emojiContainer" class="main-container">
        <img
          v-for="item in currentTabData.list"
          :key="item.id"
          :src="item.img"
          :class="['emoji-item', {'mini':currentTabData.isEmoji}]"
          @click="selectEmojiInfo(item)"
        >
      </div>
      <div class="tab-container">
        <div
          v-for="(item,index) in tabList"
          :key="index"
          class="tab-item"
          :class="{'active':currentIndex === index}"
          @click="changeTab(item,index)"
        >
          <img
            :src="item.default"
            class="tab-item-pic"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEmojiAndChartlet } from "../tools/index";

export default {
  name: "EmojiPicker",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 表情面板承载列表
      tabList: [],
      // 当前所处页签下标
      currentIndex: null
    };
  },
  mounted() {
    this.getListInfo();
  },
  computed: {
    currentTabData() {
      return this.tabList[this.currentIndex];
    }
  },
  methods: {
    /**
     * @description 获取表情列表
     */
    getListInfo() {
      this.tabList = getEmojiAndChartlet();
      this.currentIndex = 0;
    },
    /**
     * @description 选择表情
     * @param res
     */
    selectEmojiInfo(res) {
      if (this.currentTabData.isEmoji) {
        this.$emit("changeEmoji", res);
      } else {
        this.$emit("change", res);
      }
    },
    /**
     * @description 关闭表情选择面板
     */
    closeEmojiPicker() {
      this.$emit("input", false);
    },
    /**
     * @description 切换面板=>切换表情面板
     * @param tabData
     * @param index
     */
    changeTab(tabData, index) {
      this.currentIndex = index;
      const container = this.$refs.emojiContainer;
      container.scrollTop = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  .emoji-picker {
    width: 460px;
    height: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px;
    border-radius: 4px 4px 4px;
    padding: 25px 0 0;
    position: absolute;
    left: 0;
    bottom: 160px;

    .emoji-container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .main-container {
        width: 100%;
        background-color: #ffffff;
        padding: 0 16px 20px;
        height: 225px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow-y: auto;

        .emoji-item {
          width: 70px;
          height: 70px;
          padding: 4px;
          &.mini {
              width: 35px;
              height: 35px;
              padding: 4px;
          }
          &:hover {
            background-color: #F5F7FA;
            padding:1px;
            transition: all 200ms;
          }
        }
      }

      .tab-container {
        background-color: #F5F7FA;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .tab-item {
          padding: 11px 20px;
          cursor: pointer;

          &.active {
            background-color: #ffffff;
          }

          .tab-item-pic {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
</style>
