<template>
  <div v-if="isTips" class="tips-box">
    <span class="tips">{{showTipText}}</span>
  </div>
  <div v-else :class="{ 'is-self':isSelf, 'is-other':!isSelf, 'no-bubble':!showBubble, }" class="message-box">
<!--    用户头像-->
    <user-logo-info class="user-icon" :user-id="msg.from" :user-name="msg.fromNick" :user-logo="userLogo" >  </user-logo-info>
<!--    气泡-->
    <img v-if="isSelf && showBubble" src="../../../assets/chat/self.jpg" alt="" class="bubble-icon">
    <img v-else-if="!isSelf && showBubble" src="../../../assets/chat/other.jpg" alt="" class="bubble-icon">
<!--    消息体-->
    <en-button v-if="isSelf && msg.status === 'fail'" icon="tishi" icon-color="red" type="text" @click="handleResendMsg(msg)" ></en-button>
    <msg-content :msg="msg" class="message"></msg-content>
  </div>

</template>

<script>

import userLogoInfo from "@/components/user-logo-info"; // 引入用户详情弹窗组件
import { getFileUrl } from "@/tools/getFileUrl";
import msgContent from "./msgContent";

export default {
  name: "message",
  props: {
    msg: Object // 消息
  },
  components: { msgContent, userLogoInfo },
  data() {
    return {
      showPersonalInfo: false
    };
  },

  computed: {
    isSelf() {
      return this.msg.from === this.$store.state.chat.userId;
    },
    userInfo() {
      return this.$store.state.chat?.userInfos[this.msg.from];
    },
    isText() {
      return this.msg.type === "text";
    },
    isMap() {
      return this.msg.type === "geo";
    },
    isTips() {
      return this.msg.type === "timeTag" || this.msg.type === "tip" || this.msg.type === "notification";
    },
    // 大表情贴图
    isChartlet() {
      if (this.msg.type === "custom") {
        return Number(this.content.type) === 3;
      }
      return false;
    },
    content() {
      return JSON.parse(this.msg.content) || {};
    },
    showBubble() {
      return !this.isMap && !this.isChartlet;
    },
    showTipText() {
      if (this.msg.type === "tip") {
        return this.msg.tip.tip;
      }

      return this.msg.text;
    },
    userLogo() {
      if (this.userInfo?.avatar && this.userInfo?.avatar.indexOf("img.api") > 0) {
        return this.userInfo.avatar;
      } if (this.userInfo?.avatar) {
        const avatar = this.userInfo.avatar.split("://")[1].split("?")[0];
        return getFileUrl(avatar, "000");
      }
      return "";
    }
  },
  methods: {
    // /**
    //  * @description 显示个人信息展示面板
    //  */
    // handleClickUserLogo(msg) {
    //   this.$emit("clickUserLogo", msg);
    // },
    handleResendMsg(msg) {
      // 黄大佬好多功能没做啊  右键撤回\转发\提醒\删除等等
      // if (!this.isText) {
      //   this.$message("暂支持文本重发");// 云信自己也没做这些,其实可以不做,
      //   return;
      // }
      this.$emit("resend", msg);
    }
  }
};
</script>

<style scoped lang="scss">
  .tips-box + .tips-box {
    margin-top: 10px;
  }
  .tips-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px;
    position: relative;

    .tips {
      width: fit-content;
      padding: 2px 5px;
      border: 1px solid transparent;
      border-radius: 10px;
      background-color: #cdcdcd;
    }
  }
  .message-box {
    width: calc(100% - 100px);
    text-align: left;
    float: left;
    position: relative;
    padding-left: 70px;
    margin-top: 30px;

    &.no-bubble {
      .message {
        background-color: transparent !important;
      }
    }

    .bubble-icon {
      width: 26px;
      height: 42px;
      position: absolute;
      top: 7px;
    }

    .user-icon {
      position: absolute;
      cursor: pointer;
      top: 0;
    }

    &.is-self {
      text-align: right;
      float: right;
      padding-right: 70px;
      .user-icon {
        right: 20px;
        top: 0;
      }
      .bubble-icon {
        right: 70px;
      }
      .message {
        float: right;
        background-color: #3e90fe;
        color: #ffffff;
        margin: 7px 6px 7px 12px;
      }
    }

    &.is-other {
      .user-icon {
        left: 20px;
        top: 0;
      }
      .bubble-icon {
        left: 70px;
      }
      .message {
        float: left;
        background-color: #F5F7FA;
        color: #636C78;
        margin: 7px 12px 7px 6px;
      }
    }
  }
</style>
