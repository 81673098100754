<template>
  <div v-if="sessionId" class="chats-container">
    <div :class="{'show-history':showHistory}" class="chats-container-main">
      <!-- 标题区域 -->

      <chat-header :sessionId='sessionId' :billInfo="billInfo"></chat-header>
      <!-- 聊天内容呈现区域 -->
      <div ref="chatsContainer" class="right-chat-main">
          <message v-for="(item,index) in msglist" :key="index" :msg="item" @clickUserLogo="clickUserLogo" @resend="handleResendMsg"></message>
      </div>
      <!-- 流程操作按钮 -->
      <operat-buttons
          v-if="isApproval&&billInfo.mdInfo.btnList&&billInfo.mdInfo.btnList.length"
          class='operat-buttons' :size='22'
          :to='to'  :billInfo="billInfo" @success='operatSuccess'></operat-buttons>
      <!-- 底部面板及文字发送区域 -->
      <div class="right-chat-content">
        <div class="tool-bar">
          <div class="tool-bar-item" :class="{'active': showEmoji}" @click.stop="showEmojiPicker">
            <en-icon name="biaoqing" size="small" color="#3e90fe"></en-icon>
            <div class="label">表情</div>
          </div>
          <div class="tool-bar-item" @click.stop="$refs.selectFile.click()">
            <en-icon name="wenjian" size="small" color="#26C393">
            </en-icon>
            <div class="label">文件</div>
            <input type="file" ref="selectFile" v-show="false" @change="sendFileMsg">
          </div>
          <!-- <div class="tool-bar-item" :class="{'active':showApp}" @click.stop="showApplication">
            <en-icon name="faqiyingyong" size="small" color="#FFAD2C"></en-icon>
            <div class="label">发起应用</div>
          </div> -->
          <div class="tool-bar-item" :class="{'active':showHistory}" @click.stop="showHistoryMsgPanel">
            <en-icon name="bianjilishi" size="small" color="#F76B6B"></en-icon>
            <div class="label">历史记录</div>
          </div>
        </div>
        <el-input v-model="message" placeholder="请输入..." ref="msgInput" type="textarea" class="message-container" @keydown.enter="sendTextMsg"></el-input>
        <en-button class="btn-send" @click="sendTextMsg">发送</en-button>
      </div>
    </div>

    <!-- 表情选择面板 -->
    <emoji-picker v-model="showEmoji" @changeEmoji="handleSelectEmoji" @change="handleSendChartletMsg"></emoji-picker>
    <!-- 历史消息记录 -->
    <history-msg v-model="showHistory" :params="{scene,to}"></history-msg>
    <!-- 应用列表 -->
    <app-list v-model="showApp"></app-list>

  </div>
</template>

<script>

// 表情选择器
import { mapState } from "vuex";
import Utils from "@/tools/chat";
import { team } from "@/api/chat";
import message from "./message";
import emojiPicker from "./emojiPicker";

import OperatButtons from "./operatButtons";
// 历史记录面板
import historyMsg from "./historyMsg";
// 应用面板
import appList from "./appList";
//   会话头部
import chatHeader from "./chatHeader";
// 历史消息记录格式化处理、新消息格式化处理
export default {
  name: "ChatContainer",
  components: {
    emojiPicker,
    appList,
    historyMsg,
    message,
    OperatButtons,
    chatHeader
  },
  props: {
    // 是否显示聊天内容区域
    sessionId: String
  },
  data() {
    return {
      session: undefined,
      businessData: {},
      personalId: "",
      isApproval: "", // 是否关联审批流程  \
      // 是否显示表情选择
      showEmoji: false,

      // 用户信息
      userInfo: { name: "00001" },
      // 文本消息承载数据
      message: "",
      // 是否显示历史记录面板
      showHistory: false,
      // 是否显示个人信息面板
      showPersonalVisible: false,
      // 是否显示数据加载
      showLoading: false,
      // 是否显示应用列表界面
      showApp: false,
      billInfo: {} // 单据信息

    };
  },

  updated() {
    if (this.showPersonalVisible) {
      return;
    }
    this.scrollToBottom();// 任何改动都会置底
  },
  mounted() {
    this.to && this.scene && this.$store.dispatch("chat/getHistoryMsgs", { to: this.to, scene: this.scene });
  },
  computed: {
    ...mapState("chat", ["sessionlist", "sessionMap", "userInfos"]),
    chatInfo() {
      const teamList = this.$store.state.chat.teamlist;
      const team = teamList && teamList.find((team) => team.teamId === this.teamInfo.teamId);
      return team;
    },
    newMsg() {
      return this.$store.getters.getNewMsg;
    },
    nim() { // NIM配置信息
      return window.nim;
    },

    msglist() {
      return this.$store.state.chat.currSessionMsgs;
    },
    teamInfo() {
      if (this.scene === "team") {
        const teamId = this.sessionId.replace("team-", "");
        return this.$store.state.chat.teamlist.find((team) => team.teamId === teamId);
      }
      return undefined;
    },
    muteInTeam() {
      if (this.scene !== "team") return false;
      const teamMembers = this.$store.state.chat.teamMembers;
      const Members = teamMembers && teamMembers[this.teamInfo.teamId];
      const selfInTeam = Members && Members.find((item) => item.account === this.$store.chat.state.userId);
      return (selfInTeam && selfInTeam.mute) || false;
    },
    teamInvalid() {
      if (this.scene === "team") {
        return !(this.teamInfo && this.teamInfo.validToCurrentUser);
      }
      return false;
    },
    sendInvalidHint() {
      if (this.scene === "team" && this.teamInvalid) {
        return `您已不在该${this.teamInfo && this.teamInfo.type === "normal" ? "讨论组" : "群"}，不能发送消息`;
      } if (this.muteInTeam) {
        return "您已被禁言";
      }
      return "无权限发送消息";
    },
    scene() {
      return Utils.parseSession(this.sessionId)?.scene;
    },
    to() {
      return Utils.parseSession(this.sessionId)?.to;
    }

  },
  watch: {
    sessionId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) { // 切换会话对象 情况输入框
          this.message = "";
          this.session = this.sessionMap[newVal];
          !this.session && this.sendEmptyMsg();// 如果没有会话，则发送一条空信息 初始化会话窗口
          this.$nextTick(() => {
            this.$refs?.msgInput?.focus();
          });
        }
      },
      immediate: true
    },
    sessionMap: {
      handler(newVal) {
        this.session = newVal[this.sessionId];
      },
      deep: true,
      immediate: true
    },
    to: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.queryBillInfo();
        }
      },
      immediate: true
    }

  },
  methods: {
    // 操作成功回调
    operatSuccess() {
      this.queryBillInfo();
    },
    clickUserLogo(info) {
      this.showPersonalVisible = true;
      this.personalId = info.from;
    },
    // 获取单据信息
    async queryBillInfo() {
       this.billInfo = await team.getBillInfo({ sessionId: this.to });
       this.isApproval = Number(this.billInfo.isApproval);
    },

    /**
     * @description 变更群组信息
     * @param res
     */
    changeGroupInfo(res) {
      console.log(res);
    },

    /**
     * @description 重置聊天面板数据信息
     */
    clearMsgInfo() {
      this.msglist = [];
      this.message = "";
      this.showHistory = false;
      this.showEmoji = false;
      this.showPersonalVisible = false;
    },
    /**
     * @description 发送大表情消息
     * @param res
     */
    handleSendChartletMsg(res) {
      if (!res || !res.img) {
        return;
      }
      const content = {
        type: 3,
        data: {
          catalog: res.super,
          chartlet: res.name
        }
      };
      const _this = this;

      this.$store.dispatch("chat/sendMsg", {
        type: "custom",
        scene: this.scene,
        to: this.to,
        content,
        pushContent: "[贴图表情]",
        done() {
          _this.scrollToBottom();
        }
      });

      this.showEmoji = false;
    },
    handleSelectEmoji(res) {
      this.message += res.name;
    },
    /**
     * @description 发送文本消息
     */
    sendTextMsg() {
      const _this = this;
      this.$store.dispatch("chat/sendMsg", {
        type: "text",
        scene: this.scene,
        to: this.to,
        text: this.message,
        done() {
          _this.scrollToBottom();
        }
      });
      this.message = "";
    },
    sendEmptyMsg() {
      const _this = this;
      window.nim.sendTipMsg({
          scene: this.scene,
          to: this.to,
          tip: "初始化窗口",
          done() {
            _this.scrollToBottom();
          }
      });
    },
    handleResendMsg(msg) {
      this.$store.dispatch("chat/resendMsg", { msg, to: this.to, scene: this.scene });
    },
    /**
     * @description 重置滚动条位置
     */
    scrollToBottom() {
      const container = this.$refs.chatsContainer;
      if (!container) return;
      setTimeout(() => {
        container.scrollTop = container?.scrollHeight;
      }, 30);
    },
    /**
     * @description 显示历史记录面板
     */
    showHistoryMsgPanel() {
      this.showEmoji = false;
      this.showHistory = true;
    },
    /**
     * @description 显示表情选择
     */
    showEmojiPicker() {
      this.showEmoji = true;
      this.showHistory = false;
    },

    /**
     * @description 显示应用列表
     */
    showApplication() {
      this.showApp = true;
    },
    /**
     * @description 发送文件消息
     */
    sendFileMsg() {
      const inputObj = this.$refs.selectFile;
      if (!inputObj.value) {
        return;
      }

      this.$store.dispatch("chat/sendFileMsg", {
        scene: this.scene,
        to: this.to,
        fileInput: inputObj,
        done() {
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .chats-container {
    width: calc(100% - 350px);
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;

    .chats-container-main {
      width: 100%;
      background-color: #ffffff;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;

      &.show-history {
        width: calc(100% - 370px);
      }

    }
    .operat-buttons{
      position: absolute;
      right:40px;
      bottom: 170px;
    }
    /deep/ .en-title-card {
      .en-title-card-name {
        cursor: pointer;
      }
    }

    .right-chat-main {
      width: 100%;
      height: calc(100% - 210px);
      overflow-y: auto;
      padding: 10px 0 ;
    }

    .right-chat-content {
      height: 160px;
      position: relative;

      .tool-bar {
        display: flex;
        justify-content: flex-start;
        padding: 8px 20px 8px 10px;
        border-top: 1px solid #E8ECF2;

        .tool-bar-item {
          display: flex;
          justify-content: flex-start;
          line-height: 18px;
          padding: 10px 10px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          cursor: pointer;

          &.active {
            background-color: #f2f3f7;
          }

          .en-icon {
            margin-right: 5px;
          }

          .label {
            font-size: 12px;
            color: #636C78;
          }
        }
      }

      .message-container /deep/ {
        .el-textarea__inner {
          border: none
        }
      }

      .btn-send {
        width: 72px;
        position: absolute;
        right: 20px;
        bottom: 16px;
      }
    }
  }
</style>
