/*
 * @Author: wuqi
 * @Date: 2020-11-28 15:43:36
 * @LastEditors: wuqi
 * @LastEditTime: 2020-11-28 16:11:46
 * @Description:
 */
const brightKeyword = (val, keyword) => {
  if (keyword && val.indexOf(keyword) !== -1) { // 判断这个字段中是否包含keyword
    // 如果包含的话，就把这个字段中的那一部分进行替换成html字符
    return val.replaceAll(keyword, `<font color='#f60'>${keyword}</font>`);
  }
  return val;
};

export default brightKeyword;
