<template>
  <div
    v-if="value"
    v-click-outside="closeDialog"
    class="app-list"
  >
    <en-title-card
      name="发起应用"
    >
      <div name="right">
        <en-icon name="guanbi"></en-icon>
      </div>
    </en-title-card>
    <div class="app-list-main">
      <div
        v-for="item in list"
        :key="item.name"
        class="app-list-item"
      >
        <div class="app-list-title">
          {{ item.name }}
        </div>
        <div class="app-list-container">
          <div
            v-for="el in item.list"
            :key="el.name"
            class="app-item"
            @click="closeDialog"
          >
            <div class="app-item-container">
              <div class="app-item-icon">
                <en-icon
                  :name="el.icon"
                  size="20px"
                >
                </en-icon>
              </div>
              <div class="app-item-name">
                {{ el.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppList",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { list: [] };
  },
  mounted() {
    this.getAppList();
  },
  methods: {
    /**
     * @description 获取应用列表
     */
    getAppList() {
      for (let i = 0; i < 5; i++) {
        const obj = {
          name: `应用名称—${i}`,
          list: []
        };
        for (let m = 0; m < 8; m++) {
          const app = {
            name: `应用名称_${i}`,
            icon: "cheliangxinxi-wodehangcheng"
          };
          obj.list.push(app);
        }
        this.list.push(obj);
      }
    },
    /**
     * @description 关闭弹窗
     */
    closeDialog() {
      this.$emit("input", false);
    }
  }
};
</script>

<style lang="scss">
  .app-list {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    -webkit-border-radius: 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px;
    border-radius: 6px 6px 6px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .app-list-main {
      width: 100%;
      height: calc(100% - 50px);
      overflow-y: auto;

      .app-list-item {
        width: 100%;

        .app-list-title {
          text-align: left;
          padding: 20px 20px 15px 20px;
          color: #333333;
          font-weight: bold;
          font-size: 12px;
          line-height: 12px;
          border-bottom: 1px solid #E8ECF2;
        }

        .app-list-container {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 20px 20px 20px 0;

          .app-item {
            width: calc(calc(100% - 80px) / 4);
            margin-left: 20px;
            border-right: 1px solid #E8ECF2;

            padding-right: 20px;

            .app-item-container {
              padding: 12px 10px;
              -webkit-border-radius: 6px 6px 6px;
              -moz-border-radius: 6px 6px 6px;
              border-radius: 6px 6px 6px;
              width: 100%;
              display: flex;
              justify-content: flex-start;

              .app-item-icon {
                width: 50px;
                height: 50px;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
                box-shadow: 0 4px 6px 0 rgba(80, 80, 80, 0.2);
                position: relative;

                .en-icon {
                  position: absolute;
                  left: 15px;
                  top: 15px;
                }
              }

              .app-item-name {
                text-align: left;
                margin-left: 13px;
                width: calc(100% - 63px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #333333;
                font-size: 12px;
                line-height: 50px;
              }

              &:hover {
                padding: 11px 9px;
                background: #F5F9FC;
                border: 1px solid #E8ECF2;
              }
            }

            &:nth-child(4n) {
              border-right: none;
            }
          }
        }
      }
    }

  }
</style>
