var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-header-wrap" },
    [
      _c("en-title-card", {
        attrs: { name: `${_vm.sessionName}` },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.showGroupInfoPanel.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.isApproval
              ? {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "danju-btn-wrap",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewDanju.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            attrs: {
                              name: "danju-goto",
                              size: "22",
                              color: "#6ea897",
                            },
                          }),
                          _c("span", { staticClass: "danju-txt" }, [
                            _vm._v("单据"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.showGroupInfo
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeGroupSetting,
                  expression: "closeGroupSetting",
                },
              ],
              staticClass: "group-chats-setting",
            },
            [
              _c(
                "div",
                { staticClass: "personal-list" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "personal-item add-personal",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addGroupMember.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-info" },
                        [
                          _c("en-icon", {
                            attrs: { name: "tianjia-anniutoubu", size: "8px" },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "label-info" }, [
                        _vm._v(" 添加 "),
                      ]),
                    ]
                  ),
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "personal-item" },
                      [
                        _vm.teamInfo &&
                        item.imUserId !== _vm.teamInfo.owner &&
                        _vm.isOwner
                          ? _c("en-icon", {
                              staticClass: "modify-personal-btn",
                              attrs: { name: "shanchu-liebiao", size: "12px" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteGroupInfo(item, index)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("user-logo-info", {
                          ref: "userLogoInfo",
                          refInFor: true,
                          staticClass: "user-icon head-info",
                          attrs: {
                            "user-id": _vm.setIconName(item).id,
                            "user-name": _vm.setIconName(item).name,
                            "user-logo": _vm.setIconName(item).url,
                          },
                        }),
                        _c("div", { staticClass: "label-info" }, [
                          _vm._v(
                            " " + _vm._s(item.userName || item.userName) + " "
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "group-chats-setting-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 修改群名称 ")]),
                _vm.isOwner && !_vm.showEditName && _vm.teamInfo
                  ? _c(
                      "div",
                      {
                        staticClass: "right-details",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.editGroupName.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.teamInfo.name) + " ")]
                    )
                  : _vm._e(),
                !_vm.isOwner && _vm.teamInfo
                  ? _c("div", { staticClass: "right-details" }, [
                      _vm._v(" " + _vm._s(_vm.teamInfo.name) + " "),
                    ])
                  : _vm._e(),
                _vm.showEditName
                  ? _c(
                      "div",
                      { staticClass: "right-details" },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hideEditGroupName,
                              expression: "hideEditGroupName",
                            },
                          ],
                          model: {
                            value: _vm.newName,
                            callback: function ($$v) {
                              _vm.newName = $$v
                            },
                            expression: "newName",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "group-chats-setting-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 消息免打扰 ")]),
                _c(
                  "div",
                  { staticClass: "right-details" },
                  [
                    _c("el-switch", {
                      on: { change: _vm.setMuteTeam },
                      model: {
                        value: _vm.teamInfo.muteTeam,
                        callback: function ($$v) {
                          _vm.$set(_vm.teamInfo, "muteTeam", $$v)
                        },
                        expression: "teamInfo.muteTeam",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "group-chats-setting-item button" },
                [
                  _c(
                    "en-button",
                    { attrs: { type: "red" }, on: { click: _vm.outGroup } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.teamInfo.owner === _vm.userInfo.id
                              ? "解散并退出"
                              : "删除并退出"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.showaddChats
                ? _c("add-chats", {
                    ref: "teamerAddMember",
                    attrs: {
                      addMember: true,
                      owner: _vm.teamInfo.owner,
                      teamId: _vm.teamInfo.teamId,
                      exclude: _vm.list,
                    },
                    on: { change: _vm.selectTeamPerson },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.businessDialogShow
        ? _c("businessDialog", {
            attrs: {
              businessParams: _vm.businessParams,
              dialogVisible: _vm.businessDialogShow,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.businessDialogShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.businessDialogShow = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }