var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.btnList.length
    ? _c(
        "div",
        { staticClass: "operat-buttons-wrap" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                "popper-class": "btns-popover",
                placement: "left",
                width: "auto",
                trigger: "click",
              },
              on: { show: _vm.showPopover },
              model: {
                value: _vm.showpPopoverVisible,
                callback: function ($$v) {
                  _vm.showpPopoverVisible = $$v
                },
                expression: "showpPopoverVisible",
              },
            },
            [
              _c(
                "div",
                { staticClass: "btn-list" },
                _vm._l(_vm.btnList, function (btn) {
                  return _c(
                    "div",
                    {
                      key: btn.btnKey,
                      staticClass: "btn-item",
                      on: {
                        click: function ($event) {
                          return _vm.hanlderBtnClick(btn)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon-wrap" },
                        [
                          _c("en-icon", {
                            attrs: {
                              name: _vm.btnMap[btn.btnKey].name,
                              color: _vm.btnMap[btn.btnKey].color,
                              size: "20",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "btn-label" }, [
                        _vm._v(_vm._s(btn.btnName)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c("en-icon", {
                key: _vm.size,
                staticClass: "open-btn",
                attrs: {
                  slot: "reference",
                  name: _vm.iconName,
                  size: _vm.size,
                  color: "#fff",
                },
                slot: "reference",
              }),
            ],
            1
          ),
          _c("businessFunction", {
            ref: "businessFunction",
            attrs: {
              sourceType: "chat",
              id: _vm.btnObj.id,
              templateId: _vm.btnObj.templateId,
              "p-id": _vm.btnObj.pId,
            },
            on: { flowButtonProcessed: _vm.flowButtonProcessed },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }