/**
 * project: user
 * fileName: index
 * Created by 徐俊 on 2020/08/28
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import Utils from "@/tools/chat";
import { findEmoji, emoji } from "./emoji";
import { chartletList } from "./chartlet";

export function getShowHtmlText(showText) {
  // 文本消息
  showText = showText ? Utils.escape(showText) : "";
  if (/\[[^\]]+\]/.test(showText)) {
    const emojiItems = showText.match(/\[[^\]]+\]/g);
    emojiItems.forEach((text) => {
      const emoji = findEmoji(text);
      if (emoji) {
        const style = "width: 1.4rem;height: 1.4rem;vertical-align: middle;";
        showText = showText.replace(text, `<img style="${style}" src="${emoji.img}">`);
      }
    });
  }
  return showText;
}

export function getEmojiAndChartlet() {
  let list = [];
  list.push(emoji());
  list = list.concat(chartletList());
  return list;
}
