<template>
  <div v-if="value" v-click-outside="hideHistoryPanel" class="history-info">
    <en-title-card name="历史记录">
      <template #right>
        <div>
          <en-search class='search-input' placeholder="请输入关键词搜索"
              v-model="inputkey"
              clearable
              @search="handleSearch"
              @change="handleChange"
              > </en-search>
            </div>
      </template>

    </en-title-card>

   <en-tabs v-model="currentType" :list="tabList" type="white">

   </en-tabs>
   <transition name="el-fade-in-linear">
        <div ref="historyContainer" class="history-msg-list" v-show="currentType=='all'">
          <transition name="el-zoom-in-top">
            <span class='result-tip' v-show="checking">
              <span v-if="resultList.length">已搜索到<span class='tip'>{{resultList.length}}</span>条消息记录</span>
              <span v-else>搜索不到结果</span>
            </span>
          </transition>
          <template v-for="(item,index) in resultList"  >
            <template v-if="!isTips(item)">

              <div class="history-msg-item" :key="index">

                <div class="history-msg-item-name" :class="{'is-other':!isSelf(item)}">
                  {{ showTitleName(item) }}
                </div>
                <div class="history-msg-item-container">
                  <msg-content :msg="item" is-history :keywords='inputkey'></msg-content>
                </div>
              </div>
            </template>
          </template>
           <en-result v-if="!showResList.length" style="padding-top:50%" type="NoData"></en-result>
        </div>
  </transition>
  <transition name="el-fade-in-linear">
    <div ref="historyContainer" class="history-msg-list" v-if="currentType=='files'">
      <transition name="el-zoom-in-top">
        <span class='result-tip' v-show="checking">
            <span v-if="fileMsgList.length">已搜索到<span class='tip'>{{fileMsgList.length}}</span>条消息记录</span>
            <span v-else>搜索不到结果</span>
          </span>
      </transition>
      <template v-for="(item,index) in fileMsgList"  >
        <template v-if="!isTips(item)">
          <div class="history-msg-item" :key="index">
            <div class="history-msg-item-name" :class="{'is-other':!isSelf(item)}">
              {{ showTitleName(item) }}
            </div>
            <div class="history-msg-item-container">
              <msg-content :msg="item" is-history ></msg-content>
            </div>
          </div>
        </template>
      </template>
      <en-result v-if="!fileMsgList.length" style="padding-top:50%" type="NoData"></en-result>
    </div>
  </transition>
  <transition name="el-fade-in-linear">
    <div ref="historyContainer" class="history-msg-list" v-if="currentType=='pictures'">
      <transition name="el-zoom-in-top">
        <span class='result-tip' v-show="checking">
          <span v-if="imgMsgList.length">已搜索到<span class='tip'>{{imgMsgList.length}}</span>条消息记录</span>
          <span v-else>搜索不到结果</span>
        </span>
      </transition>
      <template v-for="(item,index) in imgMsgList"  >
        <template v-if="!isTips(item)">
          <div class="history-msg-item" :key="index">
            <div class="history-msg-item-name" :class="{'is-other':!isSelf(item)}">
              {{ showTitleName(item) }}
            </div>
            <div class="history-msg-item-container">
              <msg-content :msg="item" is-history ></msg-content>
            </div>
          </div>
        </template>
      </template>
       <en-result v-if="!imgMsgList.length" style="padding-top:50%" type="NoData"></en-result>
    </div>
  </transition>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce, cloneDeep } from "lodash";
import { team } from "@/api/chat";
import msgContent from "./msgContent";

export default {
  name: "HistoryMsgList",
  components: { msgContent },
  props: {

    value: {
      type: Boolean,
      default: false
    },
    params: Object// scene to
  },
  data() {
    return {
      resultList: [],
      showResList: [],
      inputkey: "",
      checking: false,
      currentType: "all",
      lastMsgId: "",
      tabList: [
        { id: "all", name: "全部" },
        { id: "files", name: "文档" },
        { id: "pictures", name: "图片" }
      ],
      userInfo: { name: "00001" }
    };
  },
  computed: {
    nim() { // NIM配置信息
      return window.nim;
    },
    msglist() {
      return this.$store.state.chat.currSessionMsgs;
    },
    startTime() {
      return this.msglist.find((item) => item.type !== "timeTag").time;
    },
    nowToal() {
      return this.msglist.filter((item) => item.type !== "timeTag").length;
    },
    fileMsgList() {
      return this.resultList.filter((item) => item.type === "file");
    },
    imgMsgList() {
      return this.resultList.filter((item) => item.type === "image");
    },
    session() {
      return this.sessionMap[this.currSessionId] || {};
    },
    ...mapState("chat", ["sessionMap", "currSessionId"])
  },
  watch: {
    value(nVal) {
      if (nVal) {
        this.checking = false;
        this.getHistoryMsg();
      }
    },
    msglist: {
      handler(newValue) {
        this.resultList = cloneDeep(newValue);
        this.showResList = this.resultList.filter((item) => !this.isTips(item));
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 搜索
    async handleSearch(key) {
      console.log("handleSearch");
      const sessionObj = { ...this.session, nt: this.nowToal, ts: this.startTime };
      const res = await team.chatRecordSearch(key, sessionObj);
      this.resultList = this.msglist.concat(res.records).filter((item) => item.type !== "timeTag" && item.type !== "notification" && item.text.includes(key));
      this.showResList = this.resultList.filter((item) => !this.isTips(item));
      this.checking = !!key;// 记录搜索状态
    },
    handleChange: debounce(function a(key) {
      if (key === "") {
        this.handleSearch(key);
      }
    }, 500),
    /**
     * @description 获取历史消息记录
     */
    getHistoryMsg() {
      const _this = this;
      const obj = { ...this.params };
      // eslint-disable-next-line func-names
      obj.done = function(error, obj) {
        if (obj.msgs && obj.msgs.length && _this.lastMsgId !== obj.lastMsgId) {
          _this.lastMsgId = obj.lastMsgId;
          _this.getHistoryMsg();
          // obj.limit = 100;
          // _this.$store.dispatch("chat/getHistoryMsgs", obj);
        } else {
          _this.resetScrollInfo();
        }
      };// 写的好简单
      obj.limit = 100;
      this.$store.dispatch("chat/getHistoryMsgs", obj);
    },

    isSelf(msg) {
      return msg.from === this.$store.state.chat.userId;
    },
    isTips(msg) {
      return msg.type === "timeTag" || msg.type === "tip" || msg.type === "notification";
    },
    showTitleName(msg) {
      return `${msg.fromNick} ${msg.showDate || ""}`;
    },
    /**
     * @description 重置滚动条位置
     */
    resetScrollInfo() {
      const container = this.$refs.historyContainer;
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 30);
    },
    /**
     * @description 外部点击关闭历史消息记录面板
     */
    hideHistoryPanel() {
      this.$emit("input", false);
    }
  }
};
</script>

<style lang="scss" scoped>
  .history-info {
    margin-left: 10px;
    width: 360px;
    height: 100%;
    background-color: #ffffff;
    -webkit-border-radius: 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px;
    border-radius: 4px 4px 4px;
    .search-input{
      width: 200px;
      margin-top: -4px;
    }
    .history-msg-list {
      overflow-y: auto;
      height: calc(100% - 50px);
      .result-tip{
        position: absolute;
        z-index: 9;
        background: #f5f9fc;
        width: 100%;
        padding: 6px 2px;
        font-size: 12px;
        text-indent: 10px;
        .tip{
          color: #fd9a4a;
        }
      }
      .history-msg-item {
        margin-top: 5px;
        padding: 5px 20px;

        &:hover {
          background-color: #F5F7FA;
        }

        .history-msg-item-name {
          text-align: left;
          width: 100%;
          font-size: 12px;
          color: #26C393;
          margin-bottom: 10px;

          &.is-other {
            color: #3e90fe;
          }
        }

        .history-msg-item-container {
          font-size: 12px;
          color: #636C78;
          text-align: left;
          /deep/ .is-share ,/deep/ .is-file {
            border-radius: 10px;
            background-color: #F5F7FA;
            padding: 0 12px;
          }
        }
      }
    }

    /deep/ .en-tabs {
      padding: 0;

      .en-tabs-list {
        width: 100%;

        .en-tabs-item {
          width: calc(100% / 3);
        }
      }
    }
  }
</style>
