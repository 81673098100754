var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeEmojiPicker,
              expression: "closeEmojiPicker",
            },
          ],
          staticClass: "emoji-picker",
        },
        [
          _c("div", { staticClass: "emoji-container" }, [
            _vm.currentTabData &&
            _vm.currentTabData.list &&
            _vm.currentTabData.list.length
              ? _c(
                  "div",
                  { ref: "emojiContainer", staticClass: "main-container" },
                  _vm._l(_vm.currentTabData.list, function (item) {
                    return _c("img", {
                      key: item.id,
                      class: [
                        "emoji-item",
                        { mini: _vm.currentTabData.isEmoji },
                      ],
                      attrs: { src: item.img },
                      on: {
                        click: function ($event) {
                          return _vm.selectEmojiInfo(item)
                        },
                      },
                    })
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tab-container" },
              _vm._l(_vm.tabList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "tab-item",
                    class: { active: _vm.currentIndex === index },
                    on: {
                      click: function ($event) {
                        return _vm.changeTab(item, index)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "tab-item-pic",
                      attrs: { src: item.default, alt: "" },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }