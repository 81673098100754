<template>

  <div class="message-container" :class="{'file-message-wrap':isFile}">
    <!--    文本-->
    <div v-if="isText" class="is-text" v-html="highLight(showHtmlText,keywords)">
    </div>
    <!--    图片-->
    <div v-else-if="isImage" class="is-image">
      <img :src='msg.file.url'  @click="downFile(msg.file)">
    </div>
    <!--    地图-->
    <div v-else-if="isMap" class="is-map">
      <img  src="../../../assets/chat/map.png" alt="" class="map">
      <div class="title">{{msg.geo.title}}</div>
    </div>
    <!--    大表情-->
    <div v-else-if="isChartlet" class="is-chartlet no-boder">
      <img :src="chartletData.img" alt="">
    </div>
    <div v-else-if="isShare" class="is-share">
      <div class="title-box">
        <div>{{shareData.title}}</div>
        <div>{{shareData.data.statusName || ''}}</div>
      </div>
      <div class="line"></div>
      <div class="detail-box">
        <div v-for="(item,index) in shareData.leftList" :key="index" class="item">
          <div class="left">{{item}}</div>
          <div class="right">{{shareData.rightList[index]}}</div>
        </div>
      </div>
    </div>
    <!--    文件-->
    <div v-else-if="isFile" class="is-file">
      <en-icon  :name="suffix(msg.file.name).iconName" :color="suffix(msg.file.name).iconColor" class="file-icon">  </en-icon>
      <div class="file-info">
        <div class="file-name">
          {{ msg.file.name  + "("+fileSizeTrans(msg.file.size)+ ")" }}
        </div>
        <div class="file-status">
          {{ "发送成功" }}
          <span class='down-btn' @click="downFile(msg.file)">下载</span>
        </div>
      </div>
    </div>
    <!--    其他(可以不用显示) -->
    <div v-else >
      {{ msg.text }}
    </div>
  </div>

</template>

<script>

import dayJs from "dayjs";
import folderComm from "@/mixins/myFolder/folderComm.js";
import brightKeyword from "@/tools/hightlight";
import { findChartlet } from "../tools/chartlet";
import { getShowHtmlText } from "../tools/index";

export default {
  name: "MsgContent",
  mixins: [folderComm],
  props: {
    msg: Object, // 消息
    keywords: String,
    isHistory: Boolean// (历史记录)
  },

  data() {
    return {
      showPersonalInfo: false
    };
  },
  computed: {
    isText() {
      return this.msg.type === "text";
    },
    isImage() {
      return this.msg.type === "image";
    },
    isFile() {
      return this.msg.type === "file";
    },
    isAudio() {
      return this.msg.type === "audio";
    },
    isVideo() {
      return this.msg.type === "video";
    },
    isMap() {
      return this.msg.type === "geo";
    },
    isTips() {
      return this.msg.type === "timeTag" || this.msg.type === "tip" || this.msg.type === "notification";
    },
    isShare() {
      if (this.msg.type === "custom") {
        return Number(this.content.type) === 1000;
      }
      return false;
    },
    // 大表情贴图
    isChartlet() {
      if (this.msg.type === "custom") {
        return Number(this.content.type) === 3;
      }
      return false;
    },
    content() {
      return JSON.parse(this.msg.content || "") || {};
    },
    chartletData() {
      let chartlet;
      const data = this.content.data;
      if (data) {
        chartlet = findChartlet(data.catalog, data.chartlet);
      }
      return chartlet || {};
    },
    shareData() {
      const share = {};
      let data = this.content.data;
      if (data) {
        share.type = Number(data.shareType);// 1,//行程单 2,//订单 3,//表单  4,//业务建模 5,//任务
        data = data.shareData;
        share.data = data;

        let title;
        let leftList;
        const rightList = [];
        if (share.type === 1) {
          title = "行程";
          leftList = ["行程时间:", "行程事由:"];
          if (!data.startTime && data.endTime) {
            rightList.push("暂无行程日期");
          } else {
            rightList.push(`${dayJs(data.startTime).format("YYYY-MM-DD")}/${dayJs(data.endTime).format("YYYY-MM-DD")}`);
          }
          rightList.push(data.memo || "");
        } else if (share.type === 2) {
          title = "订单";
          leftList = ["订单类型:"];
          rightList.push(data.name || "");
        } else if (share.type === 3) {
          title = "表单";
          leftList = ["表单名称:"];
          rightList.push(data.name || "");
        } else if (share.type === 4) {
          title = "数据";
          leftList = ["模板名称:"];
          rightList.push(data.name || "");
        } else if (share.type === 5) {
          title = "任务";
          leftList = ["任务名称:", "截止时间:"];
          rightList.push(data.name || "");
          rightList.push(dayJs(data.endTime).format("YYYY-MM-DD"));
        } else {
          title = "";
          leftList = [];
        }
        share.leftList = leftList;
        share.rightList = rightList;
        share.title = (Number(data.createType) === 0 ? "共享" : "创建") + title;
      }
      return share;
    },
    showHtmlText() {
      return getShowHtmlText(this.msg.text);
    }
  },
  methods: {
    highLight(item, highLight) {
      return brightKeyword(item, highLight);
    },
    fileSizeTrans(value) {
      if (value == null || value === "" || value < 1024) {
        return "1 KB";
      }
      const unitArr = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let index = 0;
      const srcsize = parseFloat(value);
      index = Math.floor(Math.log(srcsize) / Math.log(1024));
      let size = srcsize / (1024 ** index);
      size = size.toFixed(2);// 保留的小数位数
      return size + unitArr[index];
    },
    downFile(file) {
      console.log(file);
      // TODO file.name不能包含特殊字符 & ？
      window.open(`${file.url}&download=${file.name}`, "_blank");
    }
  }
};
</script>

<style scoped lang="scss">

  .message-container {
    font-size: 12px;
    border-radius: 20px 20px 20px;
    padding: 5px 20px 5px 20px;
    width: fit-content;
    max-width: 100%;
    /*line-height: 42px;*/
    display: flex;
    align-items: center;
    min-height: 42px;
    position: relative;

    img {
      max-width: 200px;
      max-height: 200px;
    }

    .is-text {

    }

    .is-image {
      img {
        vertical-align: middle;
      }
    }

    .is-map {
      height: 150px;

      .map {
        width: 150px;
        height: 150px;
        position: absolute;
      }
      .title {
        top: 105px;
        color: white;
        height: 45px;
        width: 150px;
        padding: 0 5px;
        position: absolute;
        display: flex !important;
        align-items: center;
        z-index: 1;
        line-height: initial;
        //多行文本
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    .is-chartlet {
      img {
        max-height: 100px;
        max-width: 100px;
        vertical-align: middle;
      }
    }

    .is-share {
      width: 220px;
      .title-box {
        line-height: 40px;
        display: flex;
        justify-content: space-between;
      }
      .line {
        height: 1px;
        background-color: white;
        width: 100%;
        margin-bottom: 10px;;
      }
      .detail-box {

        .item {
          display: flex;
          justify-content: space-between;
          line-height: 20px;
          margin-bottom: 10px;
          .left {
            width: 70px;
          }
          .right {
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }

        }
      }
    }

    .is-file {
      display: flex;
      justify-content: flex-start;
      padding: 4px 10px;
      .icon-wrap{
        background: #fff;
        text-align: center;
        width: 42px;
        height: 42px;
        border-radius: 7px;
        margin-right: 15px;
        padding: 3px;
      }
      .file-icon {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      .file-info {
        margin-top: 4px;
        text-align: left;
        line-height: 12px;

        .file-name {
          width: 100%;
          font-size: 12px;
          margin-bottom: 6px;
          color:#606266 ;
        }

        .file-status {
          font-size: 12px;
          color: #aaa;
          .down-btn{
            color:#3e90fe;
            cursor:pointer;
          }
        }
      }
    }
  }
  .file-message-wrap{
    background-color: #fff!important;
    color: #303133;
    border: 1px solid #3e90fe;
    padding:5px 7px;
  }
</style>
