<!--
 * @Author: wuqi
 * @Date: 2021-08-16 14:34:42
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-17 17:57:08
 * @Description:
-->
<template>
  <div class='operat-buttons-wrap' v-if="btnList.length">
    <el-popover
        v-model="showpPopoverVisible"
        popper-class='btns-popover'
        placement="left"
        width="auto"
        @show='showPopover'
        trigger="click"
        >
        <div class='btn-list'>
          <div class='btn-item' v-for="btn in btnList" :key="btn.btnKey" @click="hanlderBtnClick(btn)">
            <span class='icon-wrap'><en-icon :name='btnMap[btn.btnKey].name' :color="btnMap[btn.btnKey].color" size='20'></en-icon></span>
            <span class='btn-label'>{{btn.btnName}}</span>
          </div>
        </div>
        <en-icon slot="reference"  :key="size" class='open-btn' :name='iconName' :size="size" color='#fff'></en-icon>
    </el-popover>
    <businessFunction sourceType="chat" :id="btnObj.id" @flowButtonProcessed="flowButtonProcessed" ref="businessFunction" :templateId="btnObj.templateId" :p-id="btnObj.pId"></businessFunction>

  </div>
</template>

<script>

import { btnService } from "@/api/businessModel";
import businessFunction from "@/components/businessDetail/mixins/buttonFunction/businessFunction.vue";

export default {
  name: "operatButtons",
  data() {
    return {
      rejectDialogVisible: false, // 退回驳回弹窗
      rejectType: "",
      rejectContentParams: {},
      proposeType: "", // 拟办
      proposeDialogVisible: false,
      signerButton: "",
      signerDialogVisible: false,
      businessData: {},
      businessParams: {},
      showpPopoverVisible: false,
      loading: false,
      btnMap: {
        stop: { // 终止
          name: "zhongzhi-kuaijiecaozuo",
          color: "#f7bf28",
          method: this.btnStop
        },
        agree: { // 同意
          name: "tongyianniu-kuaijiecaozuo",
          color: "#49bef2",
          method: this.btnAgree
        },
        reject: { // 退回
          name: "tuihui-kuaijiecaozuo",
          color: "#ff771d",
          method: this.btnReject
        },
        signed: { // 价签
          name: "jiaqian-kuaijiecaozuo",
          color: "#4ed3c3",
          method: this.btnSigned
        },
        submit: { // 提交
          name: "tijiao-kuaijiecaozuo",
          color: "#49bef2"
        },
        getback: { // 收回
          name: "shouhui-kuaijiecaozuo",
          color: "#f7bf28"
        },
        rebut: { // 驳回
          name: "bohui-kuaijiecaozuo",
          color: "#f7bf28"
        },
        propose: { // 拟办
          name: "niban-kuaijiecaozuo",
          color: "#49bef2"
        },
        proposeTo: { // 再拟办
          name: "niban-kuaijiecaozuo",
          color: "#49bef2"
        }
      },
      btnObj: {}
    };
  },

  props: {
    size: [Number, String],
    billInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    businessFunction
  },
  computed: {
    iconName() {
      if (this.showpPopoverVisible) {
        return "shaixuantiaojianshanchu21";
      }
        return "gengduotupian-gongsiquan";
    },
    btnList() {
      const btnkeys = Object.keys(this.btnMap);
      return this.billInfo?.mdInfo?.btnList.filter((item) => btnkeys.includes(item.btnKey));
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async hanlderBtnClick(btn) {
      const mdInfo = this.billInfo?.mdInfo;
      if (btn.btnKey === "agree") {
        // 同意
       await this.btnAgree();
      } else if (btn.btnKey === "submit") {
       await this.benSubmit();
      } else {
        this.btnObj = {
          id: mdInfo.mainId,
          templateId: mdInfo.templateId,
          pId: mdInfo.pId
        };
        this.$refs.businessFunction.triggerButtonFunction(btn);
      }
    },
    operatSuccess(msg = "操作成功！") {
      this.$message.success(msg);
      this.$emit("success");
    },
    // 同意&提交公共方法
    async mdAgree() {
      const mdInfo = this.billInfo?.mdInfo;
      const params = {
          pId: mdInfo.pId,
          id: mdInfo.mainId,
          approvalComment: ""
      };
      await btnService.mdAgree(params);
    },
    // 同意
    async btnAgree() {
      if (Number(this.billInfo.businessType) === 2) {
        await this.mdAgree();
      } else {
        const fd = {
            mainId: this.billInfo.id,
            pId: this.billInfo.pId,
            approvalComment: "同意"
        };
        await btnService.chatFlowAgree(fd);
      }
      this.operatSuccess("审核成功！");
    },
    // 提交
   async benSubmit() {
       if (Number(this.billInfo.businessType) === 2) {
         await this.mdAgree();
        } else {
            const fd = {
                mainId: this.billInfo.id,
                pId: this.billInfo.pId
            };
            await btnService.chatDeagainSubmit(fd);
        }
        this.operatSuccess("提交成功！");
    },
    flowButtonProcessed() {
      this.operatSuccess();
    },
    async showPopover() {
      // await this.queryBillInfo();
      // this.loading = true;
      // await this.queryDetail();
      this.loading = false;
    }
  }
};
</script>

<style lang='scss' scoped>
.operat-buttons-wrap{
  display: inline-block;
  border-radius: 100%;
  padding: 0.5%;
  background-color: #3e90fe;
  .open-btn{
    cursor: pointer;
  }
}
.btns-popover{

  .btn-list{
    height:60px;
    border-radius:5px;
    padding:0 20px;
    background: rgba($color: #8d97a1, $alpha: 0.8);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .btn-item{
      flex:1 0 auto ;
      display: flex;
      margin: 5px;
      cursor: pointer;
      .icon-wrap{
        border-radius: 100%;
        background: #fff;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
      }
      .btn-label{
        align-self: center;
        margin-left: 5px;
        color: #fff;
      }
    }
  }
}

</style>
<style lang='scss'>
.btns-popover{
   background: transparent;
   .popper__arrow::after{
    border-left-color:rgba($color: #8d97a1, $alpha: 0.8)!important;
  }

}
</style>
