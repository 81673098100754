<template>
  <div class="chat-index">
    <!-- 左侧会话列表区域 -->
    <div class="left-chat-list" :class="{'show-container':currSessionId}">
      <chat-list></chat-list>
    </div>
    <!-- 右侧内容区域 -->
    <chat-container v-if="currSessionId" :sessionId="currSessionId"></chat-container>
  </div>
</template>

<script>
// 左侧沟通列表
import { mapState } from "vuex";
import chatList from "./view/chatList";
// 中间聊天内容区域
import chatContainer from "./view/chatContainer";

export default {
  name: "ChatIndex",
  components: {
    chatContainer,
    chatList
  },
  data() {
    return {
      // 用户信息
      userInfo: { name: "00001" },
      // SDK是否连接完成
      SDKReady: false,
      // 是否显示右侧聊天面板
      showContainer: false
    };
  },
  computed: {
    chatsInfo() {
      return this.$store.getters.getChatsInfo;
    },
    ...mapState("chat", ["currSessionId"])
  },
  watch: {
    chatsInfo(nVal) {
      if (!nVal || !nVal.dataId) {
        this.showContainer = false;
      }
      if (nVal && nVal.dataId) {
        this.showContainer = true;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.clearStoreInfo();
    });
  },
  methods: {
    /**
     * @description 清除VueX缓存信息
     */
    clearStoreInfo() {
      this.$store.commit("chat/_MxChangeVuexState", { _chatsInfo: null });
      this.$store.commit("chat/_MxChangeVuexState", { _logoutGroup: false });
      this.$store.commit("chat/_MxChangeVuexState", { _newMsg: null });
    }
  },
  beforeRouteLeave(to, from, next) {
     window.nim.resetCurrSession();
     next();
  }
};
</script>
<style>
  .micro-menu-page-container .home-router .home-content .right-area .router-show-area .loading-container .container {
    background: none !important;
  }
</style>
<style lang="scss" scoped>
  .chat-index {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left-chat-list {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      overflow-y: auto;
      transition: 0.3s ease-in-out;

      &.show-container {
        width: 340px;
      }
    }
  }
</style>
