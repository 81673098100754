<!--
 * @Author: wuqi
 * @Date: 2021-08-18 12:02:50
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-25 14:12:55
 * @Description:
-->
<template>
  <div class='chat-header-wrap'>
    <!-- 标题区域 -->
    <en-title-card :name="`${sessionName}`" @click.stop.native="showGroupInfoPanel">
      <template #right v-if="isApproval">
        <div class="danju-btn-wrap" @click.stop="viewDanju">
          <en-icon name='danju-goto' size='22' color='#6ea897'></en-icon>
          <span class='danju-txt'>单据</span>
        </div>
      </template>
    </en-title-card>
    <!-- 群信息设置 -->
   <div v-if="showGroupInfo" v-click-outside="closeGroupSetting" class="group-chats-setting" >
    <div class="personal-list" >
      <div
        class="personal-item add-personal"
        @click.stop="addGroupMember"
      >
        <div class="head-info">
          <en-icon name="tianjia-anniutoubu" size="8px"></en-icon>
        </div>
        <div class="label-info">
          添加
        </div>
      </div>
      <div
        v-for="(item,index) in list"
        :key="item.id"
        class="personal-item"
      >
        <en-icon
          v-if="teamInfo&&item.imUserId !== teamInfo.owner&&isOwner"
          name="shanchu-liebiao"
          class="modify-personal-btn"
          size="12px"
          @click.native.stop="deleteGroupInfo(item,index)"
        >
        </en-icon>
        <user-logo-info class="user-icon head-info"  ref="userLogoInfo" :user-id="setIconName(item).id" :user-name="setIconName(item).name" :user-logo="setIconName(item).url" >  </user-logo-info>
        <div class="label-info">
          {{ item.userName || item.userName }}
        </div>
      </div>
    </div>
    <div class="group-chats-setting-item" >
      <div class="label">
        修改群名称
      </div>
      <div v-if="isOwner && !showEditName&&teamInfo" class="right-details" @click.stop="editGroupName">
        {{ teamInfo.name }}
      </div>
      <div v-if="!isOwner&&teamInfo" class="right-details">
        {{ teamInfo.name }}
      </div>
      <div v-if="showEditName" class="right-details">
        <el-input v-model="newName" v-click-outside="hideEditGroupName"></el-input>
      </div>
    </div>
    <div class="group-chats-setting-item" >
      <div class="label">
        消息免打扰
      </div>
      <div class="right-details" >
        <el-switch v-model="teamInfo.muteTeam" @change="setMuteTeam">
        </el-switch>
      </div>
    </div>
    <div class="group-chats-setting-item button" >
      <en-button type="red" @click="outGroup">
        {{ teamInfo.owner === userInfo.id ? "解散并退出" : "删除并退出" }}
      </en-button>
    </div>
    <add-chats ref="teamerAddMember" :addMember='true' :owner='teamInfo.owner' v-if="showaddChats" :teamId='teamInfo.teamId'  :exclude='list'  @change="selectTeamPerson">
    </add-chats>
  </div>
  <businessDialog
      v-if="businessDialogShow"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialogShow">
    </businessDialog>
  </div>
</template>

<script>
import Utils from "@/tools/chat";
// 群组信息设置
import { team } from "@/api/chat";
import userLogoInfo from "@/components/user-logo-info"; // 引入用户详情弹窗组件
import businessDialog from "@/components/businessDetail/businessDialog";
import { viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
import { BusinessDataOpenType } from "@/components/businessDetail/data/BusinessCommonHeader";
import addChats from "./addChats";

export default {
  name: "chatHeader",
  props: {
    sessionId: [Number, String],
    billInfo: {
      type: Object,
      defatult() {
        return {};
      }
    }
  },
  components: {
    addChats,
    userLogoInfo,
    businessDialog
  },
  data() {
    return {
      showGroupInfo: false,
      showaddChats: false,
      businessDialogShow: false,
      operating: false, // 是否在设置状态，阻止v-click-outside行为
      list: [],
      newName: "",
      showEditName: false
    };
  },
  computed: {
    isApproval() {
      return Number(this.billInfo.isApproval);
    },
    teamInfo() {
      const [scene, teamId] = this.sessionId.split("-");
      if (scene === "team") {
        return this.$store.state.chat.teamlist.find((team) => team.teamId === teamId);
      }
      return undefined;
    },
    businessParams() {
      const params = {
          templateId: this.billInfo.mdInfo.templateId,
          businessType: this.billInfo.businessType,
          openType: BusinessDataOpenType.view
        };
      return { ...viewBusinessDialogParams(params), id: this.billInfo.mdInfo.mainId };
    },
    sessionName() {
      let user = null;
      if (/^p2p-/.test(this.sessionId)) {
        user = this.sessionId.replace(/^p2p-/, "");
        if (user === this.$store.state.chat.userId) {
          return "我的手机";
        }
        const userInfo = this.$store.state.chat.userInfos[user] || {};
        return Utils.getFriendAlias(userInfo) || userInfo.nick;
      } if (/^team-/.test(this.sessionId)) {
        if (this.teamInfo) {
          return this.teamInfo?.name + (this.list.length ? `(${this.list.length})` : "");
        }
        return "群";
      }
      return "";
    },
    chatsInfo() {
      return this.$store.getters.getChatsInfo;
    },
    nimInfo() { // NIM配置信息
      return window.nim;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    isOwner() {
      if (/^team-/.test(this.sessionId)) {
         return this.teamInfo && (this.userInfo.imUserId === this.teamInfo.owner);
      }
        return false;
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    // 查询单据
    async viewDanju() {
      this.businessDialogShow = true;
    },
    /**
     * @description 显示群组信息面板
     */
    showGroupInfoPanel() {
      this.showGroupInfo = !this.showGroupInfo;
    },
    setIconName(item) {
      if (item.__logoInfo) {
        return item.__logoInfo;
      }
      item.__logoInfo = {
        name: item.userName,
        url: item.url,
        id: item.imUserId,
        icon: "renyuan-wodehangcheng"
      };
      return item.__logoInfo;
    },
    addGroupMember() {
      this.showaddChats = true;// 解决穿梭框隐藏后内置dragger组件无法重新渲染的问题  // TODO en-transfer-data需要优化 1:在隐藏的时候提供可以清空内部状态的钩子
      this.$nextTick(() => {
        this.$refs.teamerAddMember.open();
      });
    },
    setMuteTeam(v) {
      this.nimInfo.updateInfoInTeam({
        teamId: this.teamInfo.teamId,
        // 静音群, 此参数为可选参数
        muteTeam: v,
        done: (error, obj) => {
          console.log(error, obj);
          if (error) {
            this.$message.error(error.message.split(":")?.[1]);
            // 清楚右侧会话
            if (error?.message.indexOf("用户不在群里面" > -1)) {
              this.$store.dispatch("chat/deleteSession", (`team-${obj.teamId}`));
              // 此处需要更改退出群聊的变量，方便左侧的会话列表进行数据清除
              this.$store.commit("chat/_MxChangeVuexState", { _logoutGroup: this.teamInfo });
            }

            this.teamInfo.muteTeam = !v;
          } else {
            console.log(obj);
          }
        }
      });
    },
    /**
     * @description 关闭群组设置面板
     */
    closeGroupSetting() {
      const userLogoList = this.$refs.userLogoInfo;//! 判断是否在查看人员信息
      const addChatRef = this.$refs.teamerAddMember;//! 判断是否在添加人员信息
      const hasDialog = userLogoList?.find((el) => el.visible) || addChatRef?.visible;
      if (!hasDialog && !this.operating) {
        this.showGroupInfo = false;
      }
    },
    /**
     * @description 获取群组成员列表
     */
    async getTeamMembers() {
      const tId = this.sessionId.split("-")[1];
     const res = await team.getGroupUserList({
        sessionId: tId
      });
      this.list = res;
    },
    /**
     * @description 群成员列表处理
     * @param dataArr 群成员列表
     */
    teamListFormat(dataArr) {
      this.list = dataArr.members;
    },
    selectTeamPerson() {
      this.getTeamMembers();
    },

    /**
     * @description 移除指定群成员
     * @param itemData 成员数据信息
     * @param index 数据下标
     */
    deleteGroupInfo(itemData, index) {
      this.operating = true;
      this.$confirm(`你确定要移除群成员：${itemData.userName}吗？`, "操作警告", { type: "warning" })
        .then(async(res) => {
          this.operating = false;
          if (res) {
            console.log("移除群成员", itemData, this);
            await team.deleteMemberKick({
              tId: this.teamInfo.teamId,
              owner: this.teamInfo?.owner,
              member: itemData.imUserId
            });
            this.nimInfo.removeTeamMembers({
              teamId: this.teamInfo.teamId,
              accounts: [itemData.imUserId],
              done: (error) => {
                if (!error) {
                  this.list.splice(index, 1);
                  this.$notify({
                    type: "success",
                    title: "操作成功",
                    message: `您已将 ${itemData.userName}移除群聊`
                  });
                } else {
                  this.$message.error(error);
                }
              }
            });
          }
        }).catch((err) => {
          console.log(err);
          this.operating = false;
        });
    },
    /**
     * @description 退出群聊或解散群聊
     */
    outGroup() {
      this.operating = true;
      if (this.isOwner) {
        this.$confirm(`您确定要解散群聊：${this.teamInfo.name}吗？`, "操作警告", { type: "warning" }).then(async(res) => {
          this.operating = false;
          if (res) {
            const data = await team.deleteMemberLeave({
              tId: this.teamInfo.teamId,
              accId: this.userInfo.imUserId
            });
            console.log(data);
            this.showGroupInfo = false;
            this.$notify({
              type: "success",
              title: "操作成功",
              message: `您已解散群聊：${this.teamInfo.name}`
            });
            // 清楚右侧会话
            this.$store.dispatch("chat/deleteSession", (`team-${this.teamInfo.teamId}`));
            // 此处需要更改退出群聊的变量，方便左侧的会话列表进行数据清除
            this.$store.commit("chat/_MxChangeVuexState", { _logoutGroup: this.teamInfo });
            this.nimInfo.dismissTeam({
              teamId: this.teamInfo.teamId
            });
          }
        }).catch((err) => {
          console.log(err);
          this.operating = false;
        });
      } else {
        this.$confirm(`您确定要退出群聊：${this.teamInfo.name}吗？`, "操作警告", { type: "warning" }).then(async(res) => {
          if (res) {
           const data = await team.deleteMemberLeave({
            tId: this.teamInfo.teamId,
            accId: this.userInfo.imUserId
           });
           console.log(data);
           this.$notify({
            type: "success",
            title: "操作成功",
            message: `您已退出群聊：${this.teamInfo.name}`
          });
           this.showGroupInfo = false;
          // 清楚右侧会话
          this.$store.dispatch("chat/deleteSession", (`team-${this.teamInfo.teamId}`));
          // 此处需要更改退出群聊的变量，方便左侧的会话列表进行数据清除
          this.$store.commit("chat/_MxChangeVuexState", { _logoutGroup: this.teamInfo });

          this.nimInfo.leaveTeam({
            teamId: this.teamInfo.teamId
          });
          }
        }).catch((err) => {
          console.log(err);
          this.operating = false;
        });
      }
    },
    /**
     * @description 显示更改群组名称
     */
    editGroupName() {
      this.showEditName = true;
      this.newName = this.teamInfo.name;
    },
    /**
     * @description 隐藏编辑群组名称并执行数据更改
     */
    hideEditGroupName() {
      if (this.newName.trim() !== "") {
        this.nimInfo.updateTeam({
          teamId: this.teamInfo.teamId,
          name: this.newName,
          done: (error) => {
            if (!error) {
              this.teamInfo.name = this.newName;
              this.$store.commit("chat/_MxChangeVuexState", { _updateGroupInfo: this.teamInfo });
            }
          }
        });
      }
      this.showEditName = false;
    }
  },
  watch: {
    sessionId: {
      handler(newVal) {
        this.operating = false;
        const scene = newVal.split("-")[0];
        scene === "team" ? this.getTeamMembers() : "";
      },
      immediate: true

    }
  }
};
</script>

<style lang='scss' scoped>
.chat-header-wrap{
  .danju-btn-wrap{
    cursor: pointer;
  }
  .danju-txt{
    height: 25px;
    line-height: 25px;
    margin-left: 5px;
  }
}
.group-chats-setting {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 2;
    width: 600px;
    background-color: #ffffff;
    border: 1px solid #E8ECF2;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    text-align: left;
    padding: 20px 20px 0 20px;

    .personal-list {
      height: 300px;
      overflow-y: auto;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-content: flex-start;
      .personal-item {
        width: 60px;
        height: 60px;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .modify-personal-btn {
          position: absolute;
          right: 10px;
          top: 0;
          z-index: 2;
          cursor: pointer;
        }

        .head-info {
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          width: 40px;
          height: 40px;
          position: relative;
        }

        .label-info {
          font-size: 12px;
          line-height: 12px;
          color: #636C78;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 7px;
        }

        &.add-personal {
          .head-info {
            border: 1px solid #E9E9E9;
            text-align: center;
            cursor: pointer;
            &:hover{
              transform: scale(1.05);
              transition: all 0.5ms ;
            }
            .en-icon {
              position: absolute;
              top: 14px;
              left: 14px;
              color: #3e90fe;
            }
          }
        }
      }
    }

    .group-chats-setting-item {
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      padding: 15px 0;
      border-top: 1px solid #E8ECF2;
      display: flex;
      justify-content: space-between;

      &.button {
        justify-content: center;
      }
    }
  }
</style>
