var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-index" },
    [
      _c(
        "div",
        {
          staticClass: "left-chat-list",
          class: { "show-container": _vm.currSessionId },
        },
        [_c("chat-list")],
        1
      ),
      _vm.currSessionId
        ? _c("chat-container", { attrs: { sessionId: _vm.currSessionId } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }