var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.hideHistoryPanel,
              expression: "hideHistoryPanel",
            },
          ],
          staticClass: "history-info",
        },
        [
          _c("en-title-card", {
            attrs: { name: "历史记录" },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _c("en-search", {
                            staticClass: "search-input",
                            attrs: {
                              placeholder: "请输入关键词搜索",
                              clearable: "",
                            },
                            on: {
                              search: _vm.handleSearch,
                              change: _vm.handleChange,
                            },
                            model: {
                              value: _vm.inputkey,
                              callback: function ($$v) {
                                _vm.inputkey = $$v
                              },
                              expression: "inputkey",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4085190097
            ),
          }),
          _c("en-tabs", {
            attrs: { list: _vm.tabList, type: "white" },
            model: {
              value: _vm.currentType,
              callback: function ($$v) {
                _vm.currentType = $$v
              },
              expression: "currentType",
            },
          }),
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentType == "all",
                    expression: "currentType=='all'",
                  },
                ],
                ref: "historyContainer",
                staticClass: "history-msg-list",
              },
              [
                _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.checking,
                          expression: "checking",
                        },
                      ],
                      staticClass: "result-tip",
                    },
                    [
                      _vm.resultList.length
                        ? _c("span", [
                            _vm._v("已搜索到"),
                            _c("span", { staticClass: "tip" }, [
                              _vm._v(_vm._s(_vm.resultList.length)),
                            ]),
                            _vm._v("条消息记录"),
                          ])
                        : _c("span", [_vm._v("搜索不到结果")]),
                    ]
                  ),
                ]),
                _vm._l(_vm.resultList, function (item, index) {
                  return [
                    !_vm.isTips(item)
                      ? [
                          _c(
                            "div",
                            { key: index, staticClass: "history-msg-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "history-msg-item-name",
                                  class: { "is-other": !_vm.isSelf(item) },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.showTitleName(item)) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "history-msg-item-container" },
                                [
                                  _c("msg-content", {
                                    attrs: {
                                      msg: item,
                                      "is-history": "",
                                      keywords: _vm.inputkey,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                }),
                !_vm.showResList.length
                  ? _c("en-result", {
                      staticStyle: { "padding-top": "50%" },
                      attrs: { type: "NoData" },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _vm.currentType == "files"
              ? _c(
                  "div",
                  { ref: "historyContainer", staticClass: "history-msg-list" },
                  [
                    _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checking,
                              expression: "checking",
                            },
                          ],
                          staticClass: "result-tip",
                        },
                        [
                          _vm.fileMsgList.length
                            ? _c("span", [
                                _vm._v("已搜索到"),
                                _c("span", { staticClass: "tip" }, [
                                  _vm._v(_vm._s(_vm.fileMsgList.length)),
                                ]),
                                _vm._v("条消息记录"),
                              ])
                            : _c("span", [_vm._v("搜索不到结果")]),
                        ]
                      ),
                    ]),
                    _vm._l(_vm.fileMsgList, function (item, index) {
                      return [
                        !_vm.isTips(item)
                          ? [
                              _c(
                                "div",
                                { key: index, staticClass: "history-msg-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "history-msg-item-name",
                                      class: { "is-other": !_vm.isSelf(item) },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.showTitleName(item)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "history-msg-item-container",
                                    },
                                    [
                                      _c("msg-content", {
                                        attrs: { msg: item, "is-history": "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    !_vm.fileMsgList.length
                      ? _c("en-result", {
                          staticStyle: { "padding-top": "50%" },
                          attrs: { type: "NoData" },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _vm.currentType == "pictures"
              ? _c(
                  "div",
                  { ref: "historyContainer", staticClass: "history-msg-list" },
                  [
                    _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checking,
                              expression: "checking",
                            },
                          ],
                          staticClass: "result-tip",
                        },
                        [
                          _vm.imgMsgList.length
                            ? _c("span", [
                                _vm._v("已搜索到"),
                                _c("span", { staticClass: "tip" }, [
                                  _vm._v(_vm._s(_vm.imgMsgList.length)),
                                ]),
                                _vm._v("条消息记录"),
                              ])
                            : _c("span", [_vm._v("搜索不到结果")]),
                        ]
                      ),
                    ]),
                    _vm._l(_vm.imgMsgList, function (item, index) {
                      return [
                        !_vm.isTips(item)
                          ? [
                              _c(
                                "div",
                                { key: index, staticClass: "history-msg-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "history-msg-item-name",
                                      class: { "is-other": !_vm.isSelf(item) },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.showTitleName(item)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "history-msg-item-container",
                                    },
                                    [
                                      _c("msg-content", {
                                        attrs: { msg: item, "is-history": "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    !_vm.imgMsgList.length
                      ? _c("en-result", {
                          staticStyle: { "padding-top": "50%" },
                          attrs: { type: "NoData" },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }