var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sessionId
    ? _c(
        "div",
        { staticClass: "chats-container" },
        [
          _c(
            "div",
            {
              staticClass: "chats-container-main",
              class: { "show-history": _vm.showHistory },
            },
            [
              _c("chat-header", {
                attrs: { sessionId: _vm.sessionId, billInfo: _vm.billInfo },
              }),
              _c(
                "div",
                { ref: "chatsContainer", staticClass: "right-chat-main" },
                _vm._l(_vm.msglist, function (item, index) {
                  return _c("message", {
                    key: index,
                    attrs: { msg: item },
                    on: {
                      clickUserLogo: _vm.clickUserLogo,
                      resend: _vm.handleResendMsg,
                    },
                  })
                }),
                1
              ),
              _vm.isApproval &&
              _vm.billInfo.mdInfo.btnList &&
              _vm.billInfo.mdInfo.btnList.length
                ? _c("operat-buttons", {
                    staticClass: "operat-buttons",
                    attrs: { size: 22, to: _vm.to, billInfo: _vm.billInfo },
                    on: { success: _vm.operatSuccess },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "right-chat-content" },
                [
                  _c("div", { staticClass: "tool-bar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tool-bar-item",
                        class: { active: _vm.showEmoji },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.showEmojiPicker.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("en-icon", {
                          attrs: {
                            name: "biaoqing",
                            size: "small",
                            color: "#3e90fe",
                          },
                        }),
                        _c("div", { staticClass: "label" }, [_vm._v("表情")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tool-bar-item",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$refs.selectFile.click()
                          },
                        },
                      },
                      [
                        _c("en-icon", {
                          attrs: {
                            name: "wenjian",
                            size: "small",
                            color: "#26C393",
                          },
                        }),
                        _c("div", { staticClass: "label" }, [_vm._v("文件")]),
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          ref: "selectFile",
                          attrs: { type: "file" },
                          on: { change: _vm.sendFileMsg },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tool-bar-item",
                        class: { active: _vm.showHistory },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.showHistoryMsgPanel.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("en-icon", {
                          attrs: {
                            name: "bianjilishi",
                            size: "small",
                            color: "#F76B6B",
                          },
                        }),
                        _c("div", { staticClass: "label" }, [
                          _vm._v("历史记录"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("el-input", {
                    ref: "msgInput",
                    staticClass: "message-container",
                    attrs: { placeholder: "请输入...", type: "textarea" },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.sendTextMsg.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.message,
                      callback: function ($$v) {
                        _vm.message = $$v
                      },
                      expression: "message",
                    },
                  }),
                  _c(
                    "en-button",
                    { staticClass: "btn-send", on: { click: _vm.sendTextMsg } },
                    [_vm._v("发送")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("emoji-picker", {
            on: {
              changeEmoji: _vm.handleSelectEmoji,
              change: _vm.handleSendChartletMsg,
            },
            model: {
              value: _vm.showEmoji,
              callback: function ($$v) {
                _vm.showEmoji = $$v
              },
              expression: "showEmoji",
            },
          }),
          _c("history-msg", {
            attrs: { params: { scene: _vm.scene, to: _vm.to } },
            model: {
              value: _vm.showHistory,
              callback: function ($$v) {
                _vm.showHistory = $$v
              },
              expression: "showHistory",
            },
          }),
          _c("app-list", {
            model: {
              value: _vm.showApp,
              callback: function ($$v) {
                _vm.showApp = $$v
              },
              expression: "showApp",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }