/**
 * project: user
 * fileName: chartlet
 * Created by 徐俊 on 2020/08/28
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

/* eslint-disable */

// enfry自定义大表情
const enfryChartlet = [
  {
    id: "enfry01", num: 24, prefix: "", suffix: "gif", default: "default.png"
  },
  {
    id: "enfry02", num: 24, prefix: "", suffix: "gif", default: "default.png"
  },
  {
    id: "enfry03", num: 24, prefix: "", suffix: "gif", default: "default.png"
  }
  // {
  //   id: "enfry04", num: 24, prefix: "", suffix: "gif", default: "default.png"
  // }//暂不支持(app 没有相关需求添加)
];
// 云信自定义表情
const yunxinChartlet = [
  {
    id: "ajmd", num: 48, prefix: "ajmd0", suffix: "png", default: "default.png"
  }, // 鸡
  {
    id: "lt", num: 20, prefix: "lt0", suffix: "png", default: "default.png"
  }, // 兔
  {
    id: "xxy", num: 40, prefix: "xxy0", suffix: "png", default: "default.png"
  }// 熊
];

/**
 * 数字补零 1 -> 0001, 22 -> 0022, (js没有其他语言的格式化输出)
 * @param num 数字x
 * @param length 总长度n
 * @returns {string} 返回0000x
 * @constructor
 */
function prefixInteger(num, length) {
  return (Array(length).join("0") + num).slice(-length);
}

/**
 * 获取单个贴图大表情数据
 * @param item 配置信息
 * @returns {{}} 返回
 */
function createChartlet(item) {
  const data = {};
  try {
    data.id = item.id;
    data.name = item.id;
    data.default = require(`@/assets/chat/${item.id}/${item.default}`);
    data.list = [];
    for (let index = 1; index <= item.num; ++index) {
      const tmp = {};
      tmp.id = `${item.id}_${index}`;
      tmp.super = item.id;
      tmp.name = `${item.prefix + prefixInteger(index, 2)}`;
      tmp.img = require(`@/assets/chat/${item.id}/${tmp.name}.${item.suffix}`);
      data.list.push(tmp);
    }
  } catch (e) {
    console.log(e);
  }
  return data;
}


const gChartletList = [];//显示使用
const gChartletMap = {};//查找使用 (key 为id+name)

(function () {
  enfryChartlet.forEach((item) => {
    gChartletList.push(createChartlet(item));
  });
  yunxinChartlet.forEach((item) => {
    gChartletList.push(createChartlet(item));
  });

  gChartletList.forEach(group => {
    group.list.forEach(item => {
      gChartletMap[item.super + item.name] = item;
    });
  })
})()


/**
 * 获取所有表情信息
 * @return {{src: string, id: string, list: *[]}[]}
 */
function chartletList() {
  return gChartletList;
}

/**
 * 查找贴图表情
 * @param id
 * @param name
 */
function findChartlet(id, name) {
  return gChartletMap[id+name];
}

export {
  // emoji表情

  // chartlet贴图大表情
  chartletList,
  // 查找表情
  findChartlet
};
