var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isTips
    ? _c("div", { staticClass: "tips-box" }, [
        _c("span", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.showTipText))]),
      ])
    : _c(
        "div",
        {
          staticClass: "message-box",
          class: {
            "is-self": _vm.isSelf,
            "is-other": !_vm.isSelf,
            "no-bubble": !_vm.showBubble,
          },
        },
        [
          _c("user-logo-info", {
            staticClass: "user-icon",
            attrs: {
              "user-id": _vm.msg.from,
              "user-name": _vm.msg.fromNick,
              "user-logo": _vm.userLogo,
            },
          }),
          _vm.isSelf && _vm.showBubble
            ? _c("img", {
                staticClass: "bubble-icon",
                attrs: {
                  src: require("../../../assets/chat/self.jpg"),
                  alt: "",
                },
              })
            : !_vm.isSelf && _vm.showBubble
            ? _c("img", {
                staticClass: "bubble-icon",
                attrs: {
                  src: require("../../../assets/chat/other.jpg"),
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.isSelf && _vm.msg.status === "fail"
            ? _c("en-button", {
                attrs: { icon: "tishi", "icon-color": "red", type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.handleResendMsg(_vm.msg)
                  },
                },
              })
            : _vm._e(),
          _c("msg-content", {
            staticClass: "message",
            attrs: { msg: _vm.msg },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }